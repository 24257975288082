/*globals process*/
import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import { linkResolver } from '../utils/linkResolver';

const PreviewPage = () => {
  return (
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
  );
};

export default withPrismicPreviewResolver(PreviewPage, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver
  }
]);
